import Chart from 'chart.js'
import colors from 'vuetify/lib/util/colors'
import { ServiceFactory } from '@/services/ServiceFactory'
const _bookingsReportService = ServiceFactory.get('BookingsReportService')
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'App',
  data () {
    return {
      activePicker: null,
      date: '',
      date1: '',
      enabled: false,
      dateRange: {
        dateStart: '',
        dateEnd: ''
      },
      menu: false,
      menu1: false,
      chartData: {},
      companies: [],
      company: '',
      chart: [],
      bookingState: ['completed', 'cancelled', 'active', 'unassisted'],
      datasets: [],
      bookingsReport: [],
      chartContainer: null,
      labels: [],
      loadingAll: false
    }
  },
  datasets: [{
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    }
  }],
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menu1 (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async bookingsChart () {
      this.chartContainer = document.getElementById('chartContainer')
      // console.log(this.chartContainer)
      // this.chartData = {
      //   labels: [],
      //   datasets: this.datasets
      //   // backgroundColor: 'transparent'
      // }
      // var canvasChart = document.getElementsByName('canvasChart')
      // console.log(canvasChart)
      // var canvas = document.getElementById('chart').getContext('2d')
      // this.chart[0] = new Chart(canvas, {
      //   type: 'bar',
      //   data: this.chartData,
      //   options: {
      //     plugins: {
      //       title: {
      //         display: true,
      //         text: 'Chart.js Bar Chart - Stacked'
      //       }
      //     },
      //     responsive: true,
      //     scales: {
      //       x: {
      //         stacked: true
      //       },
      //       y: {
      //         stacked: true
      //       }
      //     },
      //     legend: { display: false }
      //   }
      // })
      // this.chart[1] = 'chart'
    },
    async initialize () {
      this.companies = await _barsService.listBar()
      this.bookingsChart()
      // handling dates
      let date = new Date()
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      day = ('0' + day).slice(-2)
      month = ('0' + month).slice(-2)
      let currentDate = `${year}-${month}-${day}`
      this.dateRange.dateStart = currentDate
      let cont = day - 7
      let day1 = date.getDate(date.setDate(cont))
      day1 = ('0' + day1).slice(-2)
      if (month <= 1) {
        let month1 = date.getMonth(date.setMonth(month - 1))
        month1 = ('0' + month1).slice(-2)
        let year1 = date.getFullYear(date.setFullYear(year - 1))
        let oldDate = `${year1}-${month1}-${day1}`
        this.dateRange.dateEnd = oldDate
      } else if (day <= 6) {
        let month1 = date.getMonth(date.setMonth(month - 1))
        month1 = ('0' + month1).slice(-2)
        let oldDate = `${year}-${month1}-${day1}`
        this.dateRange.dateEnd = oldDate
      } else {
        let oldDate = `${year}-${month}-${day1}`
        this.dateRange.dateEnd = oldDate
      }
    },
    async getReport () {
      if (this.company !== '') {
        this.enabled = true
      }
      // console.log(this.company)
      // const bookingsReport = await _bookingsReportService.index(this.company, this.dateRange, this.bookingState)
      this.bookingsReport = await _bookingsReportService.show(this.company, this.dateRange, this.bookingState)
      // console.log(bookingsReport)
      // console.log(this.bookingsReport)
      this.labels = this.bookingsReport.labels.reverse()
      const chartContainer1 = document.getElementById('chartContainer1')
      chartContainer1.remove()
      const cC1 = document.createElement('div')
      cC1.id = 'chartContainer1'
      for (let n = 0; n < this.bookingsReport.datasets.length; n++) {
        // console.log(this.bookingsReport.datasets[n].datasets)
        const divRow = document.createElement('div')
        divRow.className = 'row mt-5'
        cC1.appendChild(divRow)
        const divCol = document.createElement('div')
        divCol.className = 'col text-center'
        divCol.style = 'width:10%'
        divRow.appendChild(divCol)
        const canvasDOM = document.createElement('canvas')
        canvasDOM.id = 'chart' + n
        canvasDOM.width = '500'
        canvasDOM.height = '120'
        divCol.appendChild(canvasDOM)
        this.chartContainer.appendChild(cC1)
        // creation of charts
        for (let i = 0; i < this.bookingsReport.datasets[n].datasets.length; i++) {
          this.bookingsReport.datasets[n].datasets[i].data.reverse()
          switch (this.bookingsReport.datasets[n].datasets[i].label) {
            case 'completed':
              this.bookingsReport.datasets[n].datasets[i].backgroundColor = colors.green.accent4
              break

            case 'cancelled':
              this.bookingsReport.datasets[n].datasets[i].backgroundColor = colors.red.accent3
              break

            case 'active':
              this.bookingsReport.datasets[n].datasets[i].backgroundColor = colors.blue.accent3
              break

            case 'unassisted':
              this.bookingsReport.datasets[n].datasets[i].backgroundColor = colors.orange.accent3
              break
            default:
              break
          }
        }
        const chartData = {
          labels: this.labels,
          datasets: this.bookingsReport.datasets[n].datasets
        }
        var canvas = document.getElementById('chart' + n).getContext('2d')
        this.chart[n] = new Chart(canvas, {
          type: 'bar',
          data: chartData,
          options: {
            title: {
              display: true,
              text: this.bookingsReport.datasets[n].name
            },
            responsive: true,
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true
              }
            },
            legend: { display: false }
          }
        })
      }
    },
    async selectAll () {
      try {
        this.loadingAll = true
        console.log(this.companies.bars)
        var selectBars = []
        this.companies.bars.map((el) => {
          selectBars.push(el.zbBarId)
        })
        var stringBars = selectBars.join()
        console.log(stringBars)
        this.company = stringBars
        this.getReport()
        this.loadingAll = false
      } catch (error) {
        console.log(error)
        this.loadingAll = false
      }
    },
    unselect () {
      this.company = ''
      const chartContainer1 = document.getElementById('chartContainer1')
      chartContainer1.remove()
      // this.initialize()
    }
  }
}
